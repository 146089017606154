import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';

const PageTemplate = ({ data: { mdx }, children }) => {
    return (
        <Layout>
            <MDXRenderer>{mdx.body}</MDXRenderer>
        </Layout>
    )
};

export const pageQuery = graphql`query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
        id
        body
    }
}`;

export default PageTemplate;
